:root {
  --background-site: #FFFFFF; /* Beispielwert */
  --brand-primary: #ab8d1d; /* Beispielwert */
  --brand-secondary: #FFFFFF; /* Beispielwert */
}

.embla {
  --slide-size: 100%;
  --slide-height: 1rem;
  box-shadow: "none";
}
.embla__viewport {
  overflow: hidden;
  box-shadow: "none";
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
 
  box-shadow: "none";
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  box-shadow: "none";
  position: relative;
}
.embla__slide__img {
  display: block;
  width: 100%; /* Stellen Sie sicher, dass die Breite auf 100% gesetzt ist */
  height: 300px; /* Ihre gewünschte Höhe */
  object-fit: cover; /* Verhindert Verzerrung und bewirkt stattdessen einen Crop */
}

.embla__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.embla__dots {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  margin-top: 1rem;
}
.embla__dot {
  width: 2.4rem;
  height: 0rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  margin-top: 0rem;
  
}
.embla__dot:after {
  background: #111423;
  border-radius: 0.2rem;
  margin-top: 0rem;
  width: 100%;
  height: 0.4rem;
  content: '';
  
 
}
.embla__dot--selected:after {
  background: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-primary)
  );

}
