body {
  font-family: 'Manrope', sans-serif;
  color: black;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}

::-webkit-scrollbar {
  width: 0px;
}

.appbar {
  color: black !important;
  padding-inline: 6%;
  background-color: #ffffff !important;
  box-shadow: none !important;
    
  
{/*}
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: left !important;
  align-content: flex-start;
*/}
}

/*
@media screen and (max-width: 800px) {
  .appbar {
    padding-inline: 2% !important;
    background-color: #ffffff !important;
  }

  .appbar-container {
    padding-inline: 0px !important;
    background-color: #ffffff !important;
  }

  .menu-item {
    color: rgb(194, 27, 27);
    font-size: 16px;
    width: 300px;
    box-shadow: none !important;
  }
}
*/ 

.cards {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.expand-icon {
  font-size: 2rem;
}

.pages {
  color: black !important;
  font-weight: 500 !important;
}

.mobile-menu {
  color: black;
  font-size: 16px;
}

.nav-link {
  text-decoration: none !important;
}

.nav-link:hover {
  text-decoration: overline !important;
  transition: ease-in-out;
}

.nav-tumlogo {
  max-width: 70px;
  margin-left: 150px;
}

.hero-image {
  max-width: 60%;
  margin-left: 25%;
  background-color: #ffffff !important;
}

.hero {
  max-width: 100%;
  background-color: #ffffff !important;
}

.hero-button {
  background-color: #ab8d1d !important; 
  color: black !important;
}

@media screen and (max-width: 900px) {
  .hero-image {
    margin-left: 0;
    margin-top: 30px;
    background-color: #ffffff !important;
  }
}

.client-business-logo {
  width: 200px;
  height: auto;
}

.client-business-logo:hover {
  transform: scale(1.07, 1.07);
  transition: transform 0.8s, -webkit-transform 0.8s;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.8s, 0.8s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
}


.Mui-expanded {
  padding: 0px !important;    
  margin-top: 15px !important;  
  margin-left: 0px !important;  
}

.MuiAccordion-root {
  padding: 0px !important;    
  margin-top: 15px !important;  
  margin-left: 0px !important;   
}

.MuiAccordionSummary-root {
  padding: 0px !important;    
  margin-left: 0px !important;   
}

.MuiAccordinDetails-root {
  transform: scale(3, 1.07);  
}

.Konsortium-info-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Konsortium-info-card:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: scale(1.07, 1.07);
  transition: 0.3s all ease-in-out;
}

/*
@media screen and (max-width:600px) {
  .Konsortium-info-card:hover {
    transform: none;
    transition: none;
  }
}
*/
.feature1-image {
  width: 100%;
  margin-left: 7%;
}

.feature2-image {
  width: 80%;
  margin-right: 7%;
}

@media screen and (max-width:600px) {
  .feature1-image {
    width: 100%;
  }

  .feature2-image {
    width: 100%;
  }
}

/*
@media screen and (max-width:600px) {
  .feature1-image {
    margin-left: 0%;
  }

  .feature2-image {
    width: 100%;
    margin-right: 0%;
  }
}
*/

.quote-icon-container {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: #1976d2;
  border-radius: 100%;
}

.quote-icon {
  color: #ffffff;
}

.star-icon {
  color: #ab8d1d !important; {/* #EC962F */}
}

.email-container {
  background-color: #ab8d1d !important; {/* #EC962F */}
  height: 300px;
  border-radius: 15px;
  box-sizing: border-box;
}

.textfield {
  background-color: #66727D;
  color: black;
  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 60% !important;
}

.tum {
  color: #ab8d1d !important; {/* #EC962F */}
}

.subscribe-button {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background-color: #ab8d1d !important; {/* #EC962F */}
  width: 30% !important;
  box-shadow: none !important;
}

.join-waitlist {
  background-color: #0B0E1E;
  border-color: #0B0E1E;
  /* #1B1C31; */
}

.footer {
  background-color: #0B0E1E;
  /* #1B1C31; */
}

.waitlist-button {
  background-color: #ab8d1d !important; {/* #EC962F */}
  color: black !important;
}

.footer-divider {
  background-color: #404444 !important;
}

.footer-link {
  text-decoration: none !important;
}

.footer-link:hover {
  text-decoration: underline !important;
  transition: ease-in-out !important;
}

.copyright {
  background-color: #0B0E1E;
  color: #ffffff;
  justify-content: center;
  text-align: center;
  border: none !important;
  /* #1B1C31; */
}

.copyrightTUMlogo {
  background-color: #0B0E1E;
}

.copyright-header {
  background-color: #0B0E1E;
  color: #ffffff;
  justify-content: center;
  text-align: center;
  border: none !important;
  /* #1B1C31; */
}

.copyright-titel {
  background-color: #0B0E1E;
  color: #ffffff;
  border: none !important;
}

.lanSwitch {
  font-size: 10px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-normal {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.image-hovered {
  opacity: 1.0;
  transition: opacity 0.3s ease-in-out;
}

.irregular-shapes
{
  transform: scale(1.0);
}
.work-svg-container {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.work-svg-container img {
  display: block;
  width: 100%;
  height: auto;
}

.path {
  transition: opacity 0.3s ease-in-out;
}
.path:hover {
  opacity: 0.2;
}

.no-border {
  border: none;
}